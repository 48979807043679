import React, { useState , useEffect } from 'react';
import './App.css';

function App() {

  
  const [step, setStep] = useState(1);
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    phone: '',
    city: '',
    jobType: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
    document.title = "JobsAbroad"; // Set your fixed title here
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const nextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const previousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const scriptURL = 'https://script.google.com/macros/s/AKfycbx4mt1xJUB3nPIe1ThPz1zlJN6Ad0N-RXzUZuzIXDpucyVxPblzrb02RCD8feEYoJx2Jg/exec';

    fetch(scriptURL, {
      method: 'POST',
      mode: 'no-cors',  // Keep no-cors mode
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(() => {
      // Since we can't check the response, assume success
      setSuccessMessage('हमारा प्लैटफ़ॉर्म अभी कार्याधीन हैं और हम आपसे जल्दी ही संपर्क करेंगे।');
      
    })
    .catch((error) => {
      console.error('Error:', error);
      setSuccessMessage('There was an error submitting your details. Please try again later.');
    });
  };

  return (
    <div className="App">
      <header className="header-section">
        <div className="header-content"></div>
      </header>

      <main>
        <section className="main-content">
          <h2>किसी भी देश में अपने सपनों की नौकरी प्राप्त करें</h2>
          <p>दुबई में रु. 2,00,000 प्रति माह की नौकरी और भी अन्य अवसर। सारे जॉब देखने के लिए साइनअप करें</p>
          <button className="cta-button" onClick={() => setFormOpen(true)}>Get Started Now</button>
        </section>

        {formOpen && (
          <div className="form-modal">
            <div className="form-container">
              <button className="close-button" onClick={() => setFormOpen(false)}>X</button>
              {successMessage ? (
                <div className="success-message">{successMessage}</div>
              ) : (
                <>
                  <h2>{step === 1 ? 'कृपया हमें अपना फ़ोन नंबर बताएं ताकि हम आपसे जुड़ सकें' : step === 2 ? 'आप वर्तमान में किस शहर में रहते हैं' : 'आप क्या काम करते है'}</h2>
                  <form onSubmit={handleSubmit}>
                    {step === 1 && (
                      <>
                        <div className="phone-input">
                          <span className="country-code">+91</span>
                          <input
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="Enter phone number"
                            pattern="[0-9]{10}"
                            required
                          />
                        </div>
                        <button type="button" className="next-button" onClick={nextStep}>Next</button>
                      </>
                    )}

                    {step === 2 && (
                      <>
                        <label>
                          <select
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Select your city</option>
                            <option value="AGRA">AGRA</option>
                            <option value="AHMEDABAD">AHMEDABAD</option>
                            <option value="ALWAR">ALWAR</option>
                            <option value="AMLA">AMLA</option>
                            <option value="AMRITSAR">AMRITSAR</option>
                            <option value="AURANGABAD">AURANGABAD</option>
                            <option value="BAMBORA">BAMBORA</option>
                            <option value="BANDHAVGARH">BANDHAVGARH</option>
                            <option value="BANGALORE">BANGALORE</option>
                            <option value="BHARATPUR">BHARATPUR</option>
                            <option value="BHOPAL">BHOPAL</option>
                            <option value="BHUBANESHWAR">BHUBANESHWAR</option>
                            <option value="BIKANER">BIKANER</option>
                            <option value="CALICUT">CALICUT</option>
                            <option value="CHAIL">CHAIL</option>
                            <option value="CHAMBA">CHAMBA</option>
                            <option value="CHANDIGARH">CHANDIGARH</option>
                            <option value="CHENNAI">CHENNAI</option>
                            <option value="CHITTORGARH">CHITTORGARH</option>
                            <option value="DALHOUSIE">DALHOUSIE</option>
                            <option value="DARJEELING">DARJEELING</option>
                            <option value="DAUSA">DAUSA</option>
                            <option value="DEHRADUN">DEHRADUN</option>
                            <option value="DHARAMSHALA">DHARAMSHALA</option>
                            <option value="DURGAPUR">DURGAPUR</option>
                            <option value="GANGTOK">GANGTOK</option>
                            <option value="GOA">GOA</option>
                            <option value="GURGAON">GURGAON</option>
                            <option value="HANSI">HANSI</option>
                            <option value="HARIDWAR">HARIDWAR</option>
                            <option value="HYDERABAD">HYDERABAD</option>
                            <option value="INDORE">INDORE</option>
                            <option value="JAIPUR">JAIPUR</option>
                            <option value="JAISALMER">JAISALMER</option>
                            <option value="JALANDHAR">JALANDHAR</option>
                            <option value="JAMSHEDPUR">JAMSHEDPUR</option>
                            <option value="JODHPUR">JODHPUR</option>
                            <option value="KANHA">KANHA</option>
                            <option value="KHIMSAR">KHIMSAR</option>
                            <option value="KOCHIN">KOCHIN</option>
                            <option value="KOLKATA">KOLKATA</option>
                            <option value="KOTA">KOTA</option>
                            <option value="LEH">LEH</option>
                            <option value="LUCKNOW">LUCKNOW</option>
                            <option value="LUDHIANA">LUDHIANA</option>
                            <option value="MADURAI">MADURAI</option>
                            <option value="MAHABALESHWAR">MAHABALESHWAR</option>
                            <option value="MANALI">MANALI</option>
                            <option value="MANDAVI">MANDAVI</option>
                            <option value="MARCHULA">MARCHULA</option>
                            <option value="MATHURA">MATHURA</option>
                            <option value="MOUNT ABU">MOUNT ABU</option>
                            <option value="MUMBAI">MUMBAI</option>
                            <option value="MUSSOORIE">MUSSOORIE</option>
                            <option value="MYSORE">MYSORE</option>
                            <option value="NAGAUR FORT">NAGAUR FORT</option>
                            <option value="NAGPUR">NAGPUR</option>
                            <option value="NAINITAL">NAINITAL</option>
                            <option value="NEW DELHI">NEW DELHI</option>
                            <option value="OOTY">OOTY</option>
                            <option value="PALAMPUR">PALAMPUR</option>
                            <option value="PALI">PALI</option>
                            <option value="PANCHKULA">PANCHKULA</option>
                            <option value="PENCH">PENCH</option>
                            <option value="PHALODI">PHALODI</option>
                            <option value="PORT BLAIR">PORT BLAIR</option>
                            <option value="PRAGPUR">PRAGPUR</option>
                            <option value="PUNE">PUNE</option>
                            <option value="PUSKHAR">PUSKHAR</option>
                            <option value="RAM NAGAR">RAM NAGAR</option>
                            <option value="RAMGARH">RAMGARH</option>
                            <option value="RANAKPUR">RANAKPUR</option>
                            <option value="RANTHAMBORE">RANTHAMBORE</option>
                            <option value="RISHIKESH">RISHIKESH</option>
                            <option value="ROHETGARH">ROHETGARH</option>
                            <option value="SASAN GIR">SASAN GIR</option>
                            <option value="SAWAI MADHOPUR">SAWAI MADHOPUR</option>
                            <option value="SHIMLA">SHIMLA</option>
                            <option value="SHIRDI">SHIRDI</option>
                            <option value="SIANA">SIANA</option>
                            <option value="SRINAGAR">SRINAGAR</option>
                            <option value="SURAT">SURAT</option>
                            <option value="THEKKADY">THEKKADY</option>
                            <option value="THIRUVANANTHAPURAM">THIRUVANANTHAPURAM</option>
                            <option value="TIRUPATI">TIRUPATI</option>
                            <option value="UDAIPUR">UDAIPUR</option>
                            <option value="VAPI">VAPI</option>
                            <option value="VISHAKAPATNAM">VISHAKAPATNAM</option>
                            
                            
                          </select>
                        </label>
                        <button type="button" className="prev-button" onClick={previousStep}>Previous</button>
                        <button type="button" className="next-button" onClick={nextStep}>Next</button>
                      </>
                    )}

                    {step === 3 && (
                      <>
                        <label>
                          <input
                            type="text"
                            name="jobType"
                            value={formData.jobType}
                            onChange={handleInputChange}
                            placeholder="E.g., Construction Worker, Driver"
                            required
                          />
                        </label>
                        <button type="button" className="prev-button" onClick={previousStep}>Previous</button>
                        <button type="submit" className="submit-button">Submit</button>
                      </>
                    )}

                   
                  </form>
                </>
              )}
            </div>
          </div>
        )}

        <section className="features-section">
          <div className="feature">
            <i className="icon">🌍</i>
            <h3>Job Opportunities</h3>
            <p>Access to a wide range of job listings abroad.</p>
          </div>
          <div className="feature">
            <i className="icon">📝</i>
            <h3>Guidance in Hindi</h3>
            <p>Expert assistance in understanding job details in Hindi.</p>
          </div>
          <div className="feature">
            <i className="icon">🚀</i>
            <h3>Support Throughout Your Journey</h3>
            <p>Continuous support from application to relocation.</p>
          </div>
        </section>
      </main>

      <footer>
        <div className="footer-content">
          <p>Contact Us: +91-1234567890 | info@jobfinder.com</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
